import React from "react"
import servicesStyles from '../../style/services.module.scss'
import Bubble from '../../img/3d/speechbubble.png'
import Bulb from '../../img//3d/bulb.png'
import ThumbsUp from '../../img/3d/thumbsup.png'
import Image from "../global/image.js"

const ConsultingImg = () => {


    return (
        <>
            <Image className={servicesStyles.bubble} src={Bubble} alt = "Services.consulting.img.1"/> 
            <Image className={servicesStyles.bulb} src={Bulb} alt = "Services.consulting.img.2"/> 
            <Image className={servicesStyles.thumbsup} src={ThumbsUp} alt = "Services.consulting.img.3"/> 
        </>
    )
}

export default ConsultingImg
