import * as React from "react"
import servicesStyles from '../../style/services.module.scss'
import Browser from '../../img/3d/browser.png'
import Pantone from '../../img/3d/pantone.png'
import Letter from '../../img/3d/letter.png'
import Image from "../global/image.js"

const DesignImg = () => {

    return (
        <>
            <Image className={servicesStyles.browser} src={Browser}  alt = "Services.design.img.1"/> 
            <Image className={servicesStyles.pantone} src={Pantone}  alt = "Services.design.img.2"/> 
            <Image className={servicesStyles.letter} src={Letter}  alt = "Services.design.img.3"/> 
        </>
    )
}

export default DesignImg
