import React from "react"
import { FormattedMessage } from 'gatsby-plugin-intl'
import Carousel from './services.carousel.js'
import LazySlide from "../global/lazy.slide.js"



const ServicesMobile = ({ firstSlide, secondSlide, thirdSlide }) => {


    return (
        <div>

            <LazySlide>
                <h2><FormattedMessage id="Services.development" /></h2>
                <Carousel
                    intString="Services.development" />
            </LazySlide>
            <LazySlide>
                <h2><FormattedMessage id="Services.infrastructure" /></h2>
                <Carousel
                    intString="Services.infrastructure" />
            </LazySlide>
            <LazySlide>
                <h2><FormattedMessage id="Services.consulting" /></h2>
                <Carousel
                    intString="Services.consulting" />
            </LazySlide>
        </div>
    )
}

export default ServicesMobile
