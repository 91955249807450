import * as React from "react"
import servicesStyles from '../../style/services.module.scss'
import Brackets from '../../img/3d/brackets.png'
import Laptop from '../../img/3d/laptop.png'
import Smartphone from '../../img/3d/smartphone.png'
import Image from "../global/image.js"

const DevelopmentImg = () => {
    return (
        <>
            <Image className={servicesStyles.brackets} src={Brackets} alt = "Services.development.img.1"/> 
            <Image className={servicesStyles.laptop} src={Laptop} alt = "Services.development.img.2"/> 
            <Image className={servicesStyles.smartphone} src={Smartphone} alt = "Services.development.img.3"/> 
        </>
    )
}

export default DevelopmentImg
