import * as React from "react"
import servicesStyles from '../../style/services.module.scss'
import WWW from '../../img/3d/www.png'
import Cloud from '../../img/3d/cloud.png'
import Server from '../../img/3d/server.png'
import Image from "../global/image.js"

const InfrastructureImg = () => {
    return (
        <>
            <Image className={servicesStyles.www} src={WWW} alt = "Services.infrastructure.img.1"/> 
            <Image className={servicesStyles.cloud} src={Cloud} alt = "Services.infrastructure.img.2"/> 
            <Image className={servicesStyles.server} src={Server} alt = "Services.infrastructure.img.3"/> 
        </>
    )
}

export default InfrastructureImg
