import React, { useState, useEffect, useRef } from "react"
import servicesStyles from '../../style/services.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'


const Carousel = ({ intString }) => {

    const [activeBtn, setActiveBtn] = useState(0)
    const refCarousel = useRef(0)
    const noOfSlides = 3;


    useEffect(() => {
        let refEl = refCarousel.current
        refEl.addEventListener("scroll", scrollDiv)
        return () => {
            refEl.removeEventListener("scroll", scrollDiv)
        }
    })

    const getBtnClass = (btnID) => {
        if (btnID === activeBtn) {
            return servicesStyles.carouselBtn_active
        } else {
            return servicesStyles.carouselBtn
        }
    }

    const scrollBtn = (clicked) => {
        setActiveBtn(clicked)
        let carouselWidth = refCarousel.current.offsetWidth
        refCarousel.current.scrollLeft = clicked % noOfSlides * carouselWidth
    }

    const scrollDiv = () => {
        let scrollPos = refCarousel.current.scrollLeft
        let carouselWidth = refCarousel.current.offsetWidth
        if (scrollPos < carouselWidth) {
            setActiveBtn(0)
        }
        else if (scrollPos < carouselWidth * 2) {
            setActiveBtn(1)
        }
        else {
            setActiveBtn(2)
        }
    }


    return (
        <div style={{ marginBottom: "5rem" }}>
            <div className={servicesStyles.carouselContent} onScroll={() => scrollDiv()} ref={refCarousel}>
                <li>
                    <div className={servicesStyles.servicesCard}>
                        <div className={servicesStyles.servicesCardContent}>
                            <h3><FormattedMessage id={intString + ".col1.h"} /></h3>
                            <p><FormattedMessage id={intString + ".col1.p"} /> </p>
                        </div>
                    </div> 
                    </li>
                <li>
                    <div className={servicesStyles.servicesCard}>
                        <div className={servicesStyles.servicesCardContent}>
                            <h3><FormattedMessage id={intString + ".col2.h"} /></h3>
                            <p><FormattedMessage id={intString + ".col2.p"} /> </p>
                        </div>
                    </div></li>
                    <li>
                    <div className={servicesStyles.servicesCard}>
                        <div className={servicesStyles.servicesCardContent}>
                            <h3><FormattedMessage id={intString + ".col3.h"} /></h3>
                            <p><FormattedMessage id={intString + ".col3.p"} /> </p>
                        </div>
                    </div></li>
            </div>
            <div className={servicesStyles.carouselBtnContainer}>
                <button onClick={() => scrollBtn(0)} className={getBtnClass(0)} />
                <button onClick={() => scrollBtn(1)} className={getBtnClass(1)} />
                <button onClick={() => scrollBtn(2)} className={getBtnClass(2)} />
            </div>

        </div>
    )
}

export default Carousel
