import React, { useEffect, useState } from "react"
import Layout from '../components/global/layout.js'
import ServicesDesktop from '../components/services/services.desktop.js'
import ServicesMobile from '../components/services/services.mobile.js'
import servicesStyles from '../style/services.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"

const ServicesPage = () => {

  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [width])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [width])

  return (

    <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <title> Spherical Elephant | Services </title>
        <meta name="description" content="Full-stack Software Design und Development - Du hast die Idee, wir erledigen den Rest! | (Wo)man Power und Know-How - Wir unterstützen deine IT Projekte!"></meta>
      </Helmet>

      <LandingSection
        h1={<FormattedMessage id="Services.h1" />}
        title={<FormattedMessage id="Services.title" />}
        subtitle="Services.subtitle"
      />

      <ContentSection>
        <div className={servicesStyles.servicesContent}>
          {width > 767 ? <ServicesDesktop /> : <ServicesMobile />}
        </div>
      </ContentSection>
    </Layout>


  )
}

export default ServicesPage
