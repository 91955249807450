import React, { useState } from "react"
import { FormattedMessage } from 'gatsby-plugin-intl'
import servicesStyles from '../../style/services.module.scss'
import DevelopmentImg from './development.images.js'
import InfrastructureImg from './infrastructure.images.js'
import ConsultingImg from './consulting.images.js'
import DesignImg from './design.images.js'
import LazySlide from "../global/lazy.slide.js"

const ServicesDesktop = () => {

  const [activeService, setActiveService] = useState(0)

  function getActiveServiceContent(contentType) {
    if (contentType === "images") {
      if (activeService === 0) {
        return <DevelopmentImg />
      }
      if (activeService === 1) {
        return <InfrastructureImg />
      }
      if (activeService === 2) {
        return <ConsultingImg />
      }
      if (activeService === 3) {
        return <DesignImg />
      }
    }
    if (contentType === "text") {
      if (activeService === 0) {
        return "Services.development"
      }
      if (activeService === 1) {
        return "Services.infrastructure"
      }
      if (activeService === 2) {
        return "Services.consulting"
      }
      if (activeService === 3) {
        return "Services.design"
      }
    }
  }

  function getBtnClass(btnID) {
    if (btnID === activeService) {
      return servicesStyles.neurobutton_active
    } else {
      return servicesStyles.neurobutton
    }
  }

  return (
    <LazySlide>
      <div className={servicesStyles.servicesDesktopContent}>

        <div className={servicesStyles.circle}>
          {getActiveServiceContent("images")}
        </div>

        <div className={servicesStyles.neuroButtons}>
          <button onClick={() => setActiveService(0)} className={getBtnClass(0)}><FormattedMessage id="Services.development" /></button>
          <button onClick={() => setActiveService(1)} className={getBtnClass(1)}><FormattedMessage id="Services.infrastructure" /></button>
          <button onClick={() => setActiveService(2)} className={getBtnClass(2)}><FormattedMessage id="Services.consulting" /></button>
        </div>

        <div className={servicesStyles.serviceTitle}>
          <h2><FormattedMessage id={getActiveServiceContent("text")} /></h2>
        </div>

        <div className={servicesStyles.descriptions}>
          <div>
            <h3><FormattedMessage id={getActiveServiceContent("text") + ".col1.h"} /></h3>
            <p><FormattedMessage id={getActiveServiceContent("text") + ".col1.p"} /> </p>
          </div>
          <div>
            <h3><FormattedMessage id={getActiveServiceContent("text") + ".col2.h"} /></h3>
            <p><FormattedMessage id={getActiveServiceContent("text") + ".col2.p"} /> </p>
          </div>
          <div>
            <h3><FormattedMessage id={getActiveServiceContent("text") + ".col3.h"} /></h3>
            <p><FormattedMessage id={getActiveServiceContent("text") + ".col3.p"} /> </p>
          </div>
        </div>
      </div>
    </LazySlide >
  )
}

export default ServicesDesktop
